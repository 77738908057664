import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { getAllTransactions, updateTransactionStatus } from "../Redux/Actions/songs";
import PaginationComponent from "../Components/Pagination";
import { base64Image, formatDateToDMY } from "../Utils/commonFiles";
import ConfirmationModal from "../Components/ConfirmationModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";

export default function Payments() {
  const dispatch = useDispatch()

  const transactionData = useSelector((e) => e.songAuth.transactionList);

  const totalEntries = transactionData?.total ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentdata, setcurrentData] = useState({});
  const [secondShow, setSecondShow] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const handleSecondClose = () => setSecondShow(false);
  const handleSecondShow = () => setSecondShow(true);
  const handleConfirmationShow = () => setConfirmationShow(false);
  const [flag, setFlag] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePayment = () => handleChangeStatus()
  const handleAccept = () => handleChangeStatus()
  const handleMakePayment = () => {
    handleSecondClose()
    setConfirmationShow(true)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeStatus = () => {
    dispatch(updateTransactionStatus({ id: currentdata?._id })).then((res) => {
      setFlag(!flag)
      handleClose()
      handleSecondClose()
      handleConfirmationShow()
    })
  };

  useEffect(() => {
    dispatch(startstopLoading(true));
    const fetchParams = { page: 0, limit: 10 };
    dispatch(getAllTransactions(fetchParams)).then(() => {
      dispatch(startstopLoading(false));
    });
  }, [dispatch, currentPage, flag]);



  const handleDownloadReceipt = (data) => {
    const doc = new jsPDF();
    const margin = 20;
    let yPos = margin;

    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = "https://bianca-violin-customer.bosselt.com/static/media/dark-logo.02d2228ae7f5e561fc80.png";

    img.onload = () => {
        // Centered Logo
        const pageWidth = doc.internal.pageSize.getWidth();
        const imgWidth = 50;
        const imgHeight = 20;
        const centerX = (pageWidth - imgWidth) / 2;

        doc.addImage(img, "PNG", centerX, yPos, imgWidth, imgHeight);
        yPos += 30;

        generatePDFContent(doc, data, yPos);
    };

    img.onerror = () => {
        console.error("Failed to load image. Using fallback base64 image.");
        const pageWidth = doc.internal.pageSize.getWidth();
        const imgWidth = 50;
        const imgHeight = 20;
        const centerX = (pageWidth - imgWidth) / 2;

        doc.addImage(base64Image, "PNG", centerX, yPos, imgWidth, imgHeight);
        yPos += 30;

        generatePDFContent(doc, data, yPos);
    };
};

const generatePDFContent = (doc, data, yPos) => {
    const margin = 20;
    const pageWidth = doc.internal.pageSize.getWidth();

    // Company Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(214, 31, 127);
    // doc.text("Bianca Blezard Violin", pageWidth / 2, yPos, { align: "center" });
    yPos += 10;

    // Receipt Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text("Payment Receipt", pageWidth / 2, yPos, { align: "center" });
    yPos += 10;

    // Horizontal Line
    doc.setLineWidth(0.5);
    doc.setDrawColor(180, 180, 180);
    doc.line(margin, yPos, pageWidth - margin, yPos);
    yPos += 8;

    // Receipt Information
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Receipt No: ${data.refNo}`, margin, yPos);
    const formattedDate = moment(data.createdAt).format("DD/MM/YYYY");
    doc.text(`Date: ${formattedDate}`, pageWidth - margin - 50, yPos);
    yPos += 8;

    // Client Information
    doc.setFont("helvetica", "bold");
    doc.text("Billed To:", margin, yPos);
    doc.setFont("helvetica", "normal");
    doc.text(data.client.fullName, margin + 25, yPos);
    yPos += 8;

    // Event Information
    doc.setFont("helvetica", "bold");
    doc.text("Event:", margin, yPos);
    doc.setFont("helvetica", "normal");
    doc.text(data.event.eventName, margin + 25, yPos);
    yPos += 8;

    doc.setFont("helvetica", "bold");
    doc.text("Event Date:", margin, yPos);
    doc.setFont("helvetica", "normal");
    const eventDate = data?.event?.eventDate ? moment(data.event.eventDate).format("DD/MM/YYYY") : "N/A";
    doc.text(eventDate, margin + 30, yPos);
    yPos += 12;

    // Payment Table
    if(data.amount === 100){
      doc.autoTable({
        startY: yPos,
        head: [["Description", "Amount"]],
        body: [["Booking Deposit", `£${data.amount}`]],
        theme: "grid",
        styles: { fontSize: 11, cellPadding: 5 },
        headStyles: { fillColor: [214, 31, 127] }, // Updated color
    });
    } else {
      doc.autoTable({
        startY: yPos,
        head: [["Description", "Amount"]],
        body: [["Remaining Balance", `£${data.amount}`]],
        theme: "grid",
        styles: { fontSize: 11, cellPadding: 5 },
        headStyles: { fillColor: [214, 31, 127] }, // Updated color
    });
    }


    // Footer Message
    yPos = doc.autoTable.previous.finalY ? doc.autoTable.previous.finalY + 15 : yPos + 20;
    doc.setFont("helvetica", "italic");
    doc.setFontSize(11);
    doc.setTextColor(119, 119, 119);
    doc.text("Thank you for your payment!", pageWidth / 2, yPos, { align: "center" });

    // Save PDF
    doc.save(`Receipt_${data.refNo}.pdf`);
};
  
  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="comm-top-title-sec">
          <h2>Payments</h2>
        </div>
        <Row>
          <Col lg={12}>
            <div className="filters mt-0">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>
                          <div className="user-checkbox">Due Date</div>
                        </th>
                        <th>Event Name </th>
                        <th>Reference No.</th>
                        <th>Amount </th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData?.transaction?.map((res) =>
                        <tr key={res._id}>
                          <td>{formatDateToDMY(res?.date)}</td>
                          <td>{res.event.eventName}</td>
                          <td>{res?.event?.reference || 'N/A'}</td>
                          <td>£{res.amount}</td>
                          {/* <td>{(res.status === 'Pending' || res.status === 'Unpaid') ? 'Pending' : res.status}</td> */}
                          <td>{res.status}</td>
                          <td>{res.status === "Complete" ? "Deposit" : "Balance"}</td>
                          <td>
                            {(res.status === 'Pending' || res.status === 'Overdue') ? <div
                              className="td-txt-theme cursor-pointer"
                              onClick={() => {
                                setcurrentData(res)
                                handleSecondShow()
                              }
                              }
                            >
                              Pay Now
                            </div> : <div className="td-txt-bg" style={{ cursor: "pointer" }} onClick={() => handleDownloadReceipt(res)}>Download Receipt</div>
                            }
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {totalPages > 1 && (
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      totalEntries={totalEntries}
                      entriesPerPage={entriesPerPage}
                    />)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Complete Your Payment!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <div className="">
              <p>
                Please use the details below to complete your payment via bank
                transfer. Ensure you include the reference number in your
                payment to help us identify your transaction.
              </p>

              <p className="mb-2">
                Payment: <b>£{currentdata?.amount}</b>
              </p>
              <p className="mb-2">
                Due Date: <b>{formatDateToDMY(currentdata?.event?.eventDate)}</b>
              </p>
              <p>
                Reference Number: <b>{currentdata?.event?.reference || 'N/A'}</b>
              </p>
              <p>Bank Details:</p>
              <ul className="list-unstyled ps-3">
                <li>
                  <p className="mb-2">
                    Account Name: <b>Bianca R Blezard</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Sort Code: <b>09-01-28</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Account Number: <b>63663012</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Bank Name: <b>Santander</b>
                  </p>
                </li>
              </ul>
              <p>
                After completing your payment, please click the button below to
                notify us.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="primary w-100" onClick={handlePayment}>
            I Made This Payment
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Complete Your Payment!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="">
            <p className="pop-small-txt">
              You have a pending payment of <b>£{currentdata?.amount}</b> for your event,{" "}
              <b>{currentdata?.event?.eventName}</b>.
            </p>
            <p>
              Please complete the payment by <b>{formatDateToDMY(currentdata?.event?.eventDate)}</b>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary w-50" onClick={handleShow}>
            View Bank Transfer Details{" "}
          </button>
          <button className="primary w-40" onClick={handleMakePayment}>
            I made this payment
          </button>
        </Modal.Footer>
      </Modal>

      <ConfirmationModal handleAccept={handleAccept} show={confirmationShow} handleClose={handleConfirmationShow} />
    </Layout>
  );
}
