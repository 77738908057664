import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesPage from "./routes/Routes";
import Loader from "./Components/Loader";
import { onForegroundMessage } from "./Utils/fireBaseConfig";

function App() {

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  useEffect(() => {
    const handleForegroundMessages = async () => {
      try {
        const payload = await onForegroundMessage();
        console.log("Received foreground message: ", payload);
        const {
          notification: { title, body },
        } = payload;
        const toastId = `notification-${payload.messageId}`;

        if (!toast.isActive(toastId)) {
          toast(<ToastifyNotification title={title} body={body} />, {
            toastId,
          });
        }
      } catch (err) {
        console.error(
          "An error occurred while retrieving foreground message: ",
          err
        );
      }
    };

    handleForegroundMessages();
  }, []);



  return (
    <div className="App">
      <ToastContainer
        limit={1}
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loader />
      <RoutesPage />
    </div>
  );
}

export default App;
