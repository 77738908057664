import React from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmationModal = ({ show, handleClose, handleAccept }) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border-none justify-content-center">
                <Modal.Title className="mt-3">Are you sure you made this payment?</Modal.Title>
            </Modal.Header>
            <Modal.Footer className="border-none justify-content-center">
                <button className="secondary" type="button" onClick={handleClose}>
                    No
                </button>
                <button className="primary" type="button" onClick={handleAccept}>
                    Yes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
