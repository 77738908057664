// src/Utils/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDry4VJT5HfBjKVsJUFkkcXhM2dEUmo-8Q",
    authDomain: "bianca-8e19c.firebaseapp.com",
    projectId: "bianca-8e19c",
    storageBucket: "bianca-8e19c.firebasestorage.app",
    messagingSenderId: "985314090513",
    appId: "1:985314090513:web:a4d61e9ea9117a999cdbb0",
    measurementId: "G-4J0CTEXZ7M"
  };

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const messaging = getMessaging(app);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BGc8bJlXSMBp_m5OtOzFnKEkhPlwoGfrNsq94vTPPFyWN5lC8f4os8q58g6oqoos1NWEAQPUcL1oOKjmXbjNqow",
      serviceWorkerRegistration,
    })
  );

const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));

export { auth, googleProvider, getFirebaseToken, onForegroundMessage };
