import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const validationSchema = Yup.object({
    fullName: Yup.string().trim().required("Full name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    // yearAndMonth: Yup.string().required("Wedding month and year are required"),
    // weddingVenue: Yup.string().required("Wedding venue is required"),
    phoneNumber: Yup.string()
        .matches(
            /^[0-9]*$/,
            "Phone number can only contain numbers"
        )
        .required("Phone number is required"),
    // venue: Yup.string().required("Venue is required"),
});

const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    yearAndMonth: "",
    weddingVenue: "",
    weddingDate: "",
    ceremonyTime: "",
    meetVenue: "",
    radioSelect: "",
    allowContact: false,
};

const RegisterComponent = () => {

    const [isShow, setIsShow] = useState(false)
    const [isShow1, setIsShow1] = useState(false)
    const [isShowDate, setIsShowDate] = useState(false);
    const [weddingDateType, setWeddingDateType] = useState("");
    const today = new Date().toISOString().split("T")[0];
    const [venue, setVenue] = useState([])

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (!values.allowContact) {
            toast.error("You must confirm contact to proceed");
            setSubmitting(false);
            return;
        }

        const payload = { ...values };
        if (!payload.meetVenue || payload.meetVenue.trim() === "") {
            delete payload.meetVenue;
        }


        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/request-form`, payload);
            if (response?.data?.statusCode === 200) {
                toast.success("Form submitted successfully");
                resetForm();
                setIsShow(false)
                setIsShow1(false)
                setIsShowDate(false)
                setWeddingDateType("")
            } else {
                toast.error(response.data.message || "An error occurred. Please try again.");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
            console.error("API call error:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const validate = (values) => {
        let errors = {};
        // Custom validation logic
        if (!values.fullName) {
            errors.fullName = "Full name is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.weddingVenue) {
            errors.weddingVenue = "Wedding venue is required";
        }
        if (weddingDateType === "" && !values.radioSelect) {
            errors.radioSelect = "Please choose a wedding date type";
        }
        if (weddingDateType === "exact" && !values.weddingDate) {
            errors.weddingDate = "Wedding date is required";
        }
        if (weddingDateType === "approximate" && !values.yearAndMonth) {
            errors.yearAndMonth = "Wedding month and year are required";
        }
        return errors;
    };


    const handleBlur = (e) => {
        setIsShow(true);
    };

    const handleBlur1 = (e) => {
        setIsShow1(true);
    };

    const handleDate = (e) => {
        setIsShowDate(true);
    };

    const Initialize = async () => {
        try {
            const apiRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}auth/location`);
            let data = apiRes?.data?.data
            if (data) {
                setVenue(data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        Initialize()
    }, [])

    return (
        <div className="p-3">
            <Container fluid>
                <div className="row sign-banner-part">
                    <Col lg={12}>
                        <div className="right-banner-part">
                            <div className="login-cmn-box">
                                <div className="login-logo mb-0">
                                    <img src={require("../Assets/Images/dark-logo.png")} alt="Logo" />
                                </div>
                                <div className="login-box-inner-wrap register-box-page">

                                    <h2>Register</h2>
                                    <p>Enter your details below and we'll send over some more information about our packages</p>

                                    <Formik
                                        initialValues={initialValues}
                                        validate={validate}
                                        onSubmit={handleSubmit}
                                        validationSchema={validationSchema}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                {/* {isSubmitting && (
                                                    <div className="global-error text-danger p-2 mb-3">
                                                        Submitting...
                                                    </div>
                                                )} */}
                                                <div className="form-set">
                                                    <div className="mb-3">
                                                        <label htmlFor="fullName">Full Name</label>
                                                        <Field
                                                            type="text"
                                                            name="fullName"
                                                            id="fullName"
                                                            className="form-control"
                                                            placeholder="Type your name here"
                                                        />
                                                        <ErrorMessage
                                                            name="fullName"
                                                            component="div"
                                                            className="text-danger small"
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="email">Email</label>
                                                        <Field
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className="form-control"
                                                            placeholder="email@email.com"
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="text-danger small"
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="phoneNumber">Phone Number</label>
                                                        <Field
                                                            type="text"
                                                            name="phoneNumber"
                                                            id="phoneNumber"
                                                            className="form-control"
                                                            placeholder="Type your number here"
                                                            maxLength={15}
                                                        />
                                                        <ErrorMessage
                                                            name="phoneNumber"
                                                            component="div"
                                                            className="text-danger small"
                                                        />
                                                    </div>

                                                    <div className="radio-group">
                                                        {weddingDateType === "" && (
                                                            <ErrorMessage
                                                                name="radioSelect"
                                                                component="div"
                                                                className="text-danger small"
                                                            />
                                                        )}
                                                        <label>Select Wedding Date Type</label>
                                                        <div className="radio-option">
                                                            <Field
                                                                type="radio"
                                                                name="weddingDateType"
                                                                value="exact"
                                                                checked={weddingDateType === "exact"}
                                                                onChange={() => setWeddingDateType("exact")}
                                                                className="custom-radio"
                                                            />
                                                            <div>I know my exact wedding date</div>
                                                        </div>
                                                        <div className="radio-option">
                                                            <Field
                                                                type="radio"
                                                                name="weddingDateType"
                                                                value="approximate"
                                                                checked={weddingDateType === "approximate"}
                                                                onChange={() => setWeddingDateType("approximate")}
                                                                className="custom-radio"
                                                            />
                                                            <div>I have an approximate date</div>
                                                        </div>
                                                    </div>


                                                    {weddingDateType === "exact" && (
                                                        <div className="mb-3 date-picker-custom position-relative">
                                                            <label htmlFor="weddingDate">Wedding Date</label>
                                                            <Field
                                                                type="date"
                                                                name="weddingDate"
                                                                id="weddingDate"
                                                                className="form-control"
                                                                min={today}
                                                                onInput={() => setIsShowDate(true)}
                                                            />
                                                            <span className={`${isShowDate ? "active" : ""}`}>Select the Date</span>
                                                            <ErrorMessage
                                                                name="weddingDate"
                                                                component="div"
                                                                className="text-danger small"
                                                            />
                                                        </div>
                                                    )}

                                                    {weddingDateType === "approximate" && (
                                                        <div className="mb-3 date-picker-custom position-relative">
                                                            <label htmlFor="yearAndMonth">Wedding Month & Year</label>
                                                            <Field
                                                                type="month"
                                                                name="yearAndMonth"
                                                                id="yearAndMonth"
                                                                className="form-control"
                                                                min={today.slice(0, 7)}
                                                                onInput={() => setIsShow(true)}
                                                            />
                                                            <span className={`${isShow ? "active" : ""}`}>Select the month and year</span>
                                                            <ErrorMessage
                                                                name="yearAndMonth"
                                                                component="div"
                                                                className="text-danger small"
                                                            />
                                                        </div>
                                                    )}


                                                    <div className="mb-3">
                                                        <label htmlFor="weddingVenue">Wedding Venue</label>
                                                        <Field
                                                            type="text"
                                                            name="weddingVenue"
                                                            id="weddingVenue"
                                                            className="form-control"
                                                            placeholder="Type your venue here"
                                                        />
                                                        <ErrorMessage
                                                            name="weddingVenue"
                                                            component="div"
                                                            className="text-danger small"
                                                        />
                                                    </div>

                                                    <div className="mb-3 date-picker-custom position-relative">
                                                        <label htmlFor="ceremonyTime">Ceremony Time (if known)</label>
                                                        <Field
                                                            type="time"
                                                            name="ceremonyTime"
                                                            id="ceremonyTime"
                                                            className="form-control"
                                                            placeholder="Type your venue time here"
                                                            onInput={handleBlur1}
                                                        />
                                                        <span className={`${isShow1 ? "active" : ""}`}>Ceremony Time (if known)</span>
                                                    </div>


                                                    {/* <div className="mb-3">
                                                        <label htmlFor="meetVenue">Where did you meet me?</label>
                                                        <Field
                                                            type="text"
                                                            name="meetVenue"
                                                            id="meetVenue"
                                                            className="form-control"
                                                            placeholder="Type the place here"
                                                        />
                                                    </div> */}
                                                    {venue?.length ? <div className="mb-3">
                                                        <label htmlFor="meetVenue">Where did you meet me?</label>
                                                        <Field
                                                            id="meetVenue"
                                                            as="select"
                                                            name="meetVenue"
                                                            className="form-select"
                                                            defaultValue=""
                                                        >
                                                            <option value="" disabled>
                                                                Select Venue
                                                            </option>
                                                            {venue?.map((res) => (
                                                                <option key={res._id} value={res._id}>
                                                                    {res.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="meetVenue"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div> : null}

                                                    <div className="mb-2 d-flex align-items-center">
                                                        <Field
                                                            type="checkbox"
                                                            name="allowContact"
                                                            id="allowContact"
                                                            className="me-2 regi-checkbox"
                                                        />
                                                        <label>
                                                            I confirm, I allow Bianca Violin to contact me about her services
                                                        </label>

                                                    </div>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Register
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </div>
    );
};

export default RegisterComponent;
